import { PositionModel } from '@/models/PositionModel';
import { EventDayModel } from '@/models/EventDayModel';
import { ConfigurationModel } from '@/models/ConfigurationModel';
import { TimeSlotModel } from '@/models/TimeSlotModel';
import { ParticipationModel } from './ParticipationModel';
import { CampaignModel } from './CampaignModel';
import IntervalTree from '@flatten-js/interval-tree';
import { CustomFieldModel } from './CustomFieldModel';
import { CampaignFieldModel } from './CampaignFieldModel';
import { DateTime } from 'luxon';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { TagModel } from './TagModel';
import { RewardCategoryModel } from './RewardCategoryModel';
import * as Utils from '@/assets/js/Utils.js';

export enum EventStatus {
  Draft,
  Published,
  Archived,
}

export class EventModel {

  public static empty() {
    return new EventModel();
  }

  public id: number | null = null;
  public organizationId: number | null = null;
  public description: string;
  public email: string;
  public responsable: string;
  public status: EventStatus;
  public complexEvent: boolean;
  public positions: PositionModel[];
  public campaignFields: CampaignFieldModel[] = [];
  public configuration: ConfigurationModel;
  public logo: string;
  public teaser: string;
  public website: string;
  public base64Logo: string;
  public versionEhro: number;
  public participationsRankings: number[] = [];
  public participationsRankingsUrls: string[] = [];

  public organizationName: string = '';
  public nbrVolunteersRequired: number | null = null;
  public nbrVolunteersRegistered: number | null = null;
  public showOnPublicPage: boolean = true;
  public showOnHomeCalendar: boolean = true;
  public timeZone: string = '';
  public images: any[] = [];
  public overviewSecret: string = '';

  public bulkShiftsToBeCreated: TimeSlotModel[] = [];


  private _bulkShiftsToBeUpdated: TimeSlotModel[] = [];
  public get bulkShiftsToBeUpdated(): TimeSlotModel[] {
    return this._bulkShiftsToBeUpdated;
  }
  public addShifToBeUpdated(slot: TimeSlotModel) {
    const index = this._bulkShiftsToBeUpdated.findIndex((shift) => { return shift.id === slot.id });
    if (index === -1) {
      this._bulkShiftsToBeUpdated.push(slot);
    } else {
      this._bulkShiftsToBeUpdated[index] = slot;
    }
  }

  public bulkShiftsToBeDeleted: TimeSlotModel[] = [];
  public get bulkShiftsToBeDeletedIds(): number[] {
    let ids: number[] = [];
    this.bulkShiftsToBeDeleted.forEach((s) => {
      if (s.id) {
        ids.push(s.id);
      }
    });
    return ids;
  }
  public bulkReset() {
    this.bulkShiftsToBeCreated = [];
    this.bulkShiftsToBeDeleted = [];
    this._bulkShiftsToBeUpdated = [];
  }

  // public campaignFieldsForPopUp: CampaignFieldModel[] = [];
  public zoomLevel: number; // 0 .. 3
  private zoom = [5, 15, 30, 60];
  private size = [25, 25, 40, 60];

  private iName = '';
  private iDays: EventDayModel[] = [];
  public deepUpdateNeeded = false;

  private _uuidLong: string | null = null;
  private _uuidShort: string | null = null;

  get name(): string {
    if (this.status === EventStatus.Archived) {
      return '[ARCHIVE] ' + this.iName;
    }
    return this.iName;
  }

  set name(newName: string) {
    this.iName = newName;
  }

  get long_uuid_warning(): string | null {
    return this._uuidLong;
  }

  get uuid(): string | null {
    if (this._uuidShort) {
      return this._uuidShort;
    } else if (this._uuidLong) {
      return this._uuidLong;
    } else {
      return null;
    }
  }

  set uuid(value: string | null) {
    // Read-only !
  }

  public rewardCategories: RewardCategoryModel[] = [];

  public getRewardCategoryFromId(id: number): RewardCategoryModel | null {
    return RewardCategoryModel.getRewardCategoryFromId(id, this.rewardCategories);
  }

  public getDefaultRewardCategory(): RewardCategoryModel | null {
    return RewardCategoryModel.getDefaultRewardCategory(this.rewardCategories);
  }


  constructor({
    id = null,
    uuid = null,
    uuidShort = null,
    organizationId = null,
    name = '',
    description = '',
    email = '',
    responsable = '',
    status = EventStatus.Draft,
    days = [],
    positions = [],
    images = [],
    configuration = new ConfigurationModel(),
    logo = '',
    teaser = '',
    website = '',
    base64Logo = '',
    complexEvent = false,
    versionEhro = 2,
    showOnPublicPage = true,
    showOnHomeCalendar = true,
    participationsRankings = [],
    participationsRankingsUrls = [],
    zoomLevel = 1,
    timeZone = '',
    overviewSecret = '',
  }: {
    id?: number | null,
    uuid?: string | null,
    uuidShort?: string | null,
    organizationId?: number | null,
    name?: string,
    description?: string,
    email?: string,
    responsable?: string,
    status?: EventStatus,
    days?: any[],
    positions?: any[],
    images?: any[],
    configuration?: ConfigurationModel,
    logo?: string,
    teaser?: string,
    website?: string,
    base64Logo?: string,
    complexEvent?: boolean,
    versionEhro?: number,
    showOnPublicPage?: boolean,
    showOnHomeCalendar?: boolean,
    participationsRankings?: number[],
    participationsRankingsUrls?: string[],
    zoomLevel?: number,
    timeZone?: string,
    overviewSecret?: string,
  } = {}) {
    this.id = id;
    this._uuidLong = uuid;
    this._uuidShort = uuidShort;
    this.logo = logo;
    this.images = images;
    this.teaser = teaser;
    this.website = website;
    this.base64Logo = base64Logo;
    this.complexEvent = complexEvent;
    this.versionEhro = versionEhro;
    this.organizationId = organizationId;
    this.iName = name;
    this.description = description;
    this.email = email;
    this.responsable = responsable;
    this.showOnPublicPage = showOnPublicPage;
    this.showOnHomeCalendar = showOnHomeCalendar;
    this.status = status;
    this.zoomLevel = zoomLevel;
    this.overviewSecret = overviewSecret;
    const tmpDays = days.map((d: any): EventDayModel => {
      if (d instanceof EventDayModel) {
        return d;
      } else {
        return new EventDayModel({
          startsAt: DateTime.fromJSDate(d.startsAt).setZone(timeZone),
          endsAt: DateTime.fromJSDate(d.endsAt).setZone(timeZone),
        });
      }
    });
    this.iDays = tmpDays.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });
    this.positions = positions.map((p: any, i: number): PositionModel => {
      if (p instanceof PositionModel) {
        return p;
      } else {
        p.eventTimeZone = timeZone;
        return new PositionModel(p);
      }
    });
    this.orderPositionInArray();
    if (configuration instanceof ConfigurationModel) {
      this.configuration = configuration;
    } else {
      this.configuration = new ConfigurationModel(configuration);
    }
    this.participationsRankings = participationsRankings || [];
    this.participationsRankingsUrls = participationsRankingsUrls || [];
    this.timeZone = timeZone;
  }

  public toJSON(): any {
    return {
      id: this.id,
      uuid: this._uuidLong,
      organizationId: this.organizationId,
      name: this.iName,
      description: this.description,
      email: this.email,
      teaser: this.teaser,
      website: this.website,
      responsable: this.responsable,
      status: this.status,
      complexEvent: this.complexEvent,
      days: this.iDays.map((day: EventDayModel): any => day.toJSON()),
      positions: this.positions.map((p: PositionModel): any => {
        return p.toJSON();
      }),
      configuration: this.configuration.toJSON(),
      base64Logo: this.base64Logo,
      // images: this.images,
      images: this.images.map((image) => {
        if (image.id) {
          return {
            id: image.id,
          };
        } else {
          return image;
        }
      }),
      versionEhro: this.versionEhro,
      showOnPublicPage: this.showOnPublicPage,
      showOnHomeCalendar: this.showOnHomeCalendar,
      participationsRankings: this.participationsRankings,
      zoomLevel: this.zoomLevel,
      timeZone: this.timeZone,
    };
  }

  get numberOfShifts() {
    return this.positions.reduce((r, pos) => r + pos.timeSlots.length, 0);
  }


  public get style(): string {
    return `background-color:${this.configuration.colorFree}; color:${Utils.getTextColor(this.configuration.colorFree)}`;
  }

  private orderPositionInArray() {
    this.positions = this.positions.slice().sort((a, b) => {
      if (a.order < b.order) { return -1; }
      if (a.order > b.order) { return 1; }
      return 0;
    });
  }

  public orderPositionInArrayByName(asc: boolean) {
    this.positions = this.positions.slice().sort((a, b) => {
      if (a.name < b.name) { return asc ? -1 : 1; }
      if (a.name > b.name) { return asc ? 1 : -1; }
      return 0;
    });
  }

  public get positionsWaitingForUpdate(): PositionModel[] {
    return this.positions.filter((p) => p.updateNeeded);
  }


  public get imagesForUpdate() {
    return this.images.map((image) => {
      if (image.id) {
        return {
          id: image.id,
        };
      } else {
        return image;
      }
    });
  }

  public get days(): EventDayModel[] {
    return this.iDays;
  }
  public set days(days: EventDayModel[]) {
    const tmpDays = days.map((d: any): EventDayModel => {
      if (d instanceof EventDayModel) {
        return d;
      } else {
        return new EventDayModel(d);
      }
    });
    this.iDays = tmpDays.sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    });
  }

  public containsDate(date: DateTime): boolean {
    let res = false;
    this.days.forEach((d) => {
      res ||= (d.startsAtYYYYMMDD() === date.toFormat('yyyy-LL-dd'));
    });
    return res;
  }

  public get minDateJS(): Date {
    if (this.days.length > 0) {
      return this.days[0].startOfDayDateOfStarts.toJSDate();
    }
    return DateTime.now().toJSDate();
  }

  public get minDateString(): string {
    if (this.days.length > 0) {
      return this.days[0].startsAtYYYYMMDD();
    }
    return DateTime.now().toFormat('yyyy-LL-dd');
  }

  public get maxDateJS(): Date {
    if (this.days.length > 0) {
      return this.days[this.days.length - 1].endsAt.toJSDate();
    }
    return DateTime.now().toJSDate();
  }

  public get maxDateString(): string {
    if (this.days.length > 0) {
      return this.days[this.days.length - 1].startOfDayDateOfEnds.toFormat('yyyy-LL-dd');
    }
    return DateTime.now().toFormat('yyyy-LL-dd');
  }

  public createPosition(name: string): PositionModel {
    const newPosition = PositionModel.empty();
    newPosition.name = name;
    newPosition.order = this.positions.length;
    return newPosition;
  }

  public updatePositionsOrder() {
    this.positions.slice().forEach((position, index) => {
      position.updateNeeded = position.order !== index
      position.order = index;
    });
  }

  public deletePosition(position: PositionModel) {
    const index = this.positions.indexOf(position, 0);
    if (index > -1) {
      this.positions.splice(index, 1);
      this.updatePositionsOrder();
    }
  }

  public get hasSlot(): boolean {
    for (const position of this.positions) {
      if (position.timeSlots.length > 0) {
        return true;
      }
    }
    return false;
  }

  public get slots(): TimeSlotModel[] {
    const slots: TimeSlotModel[] = [];
    this.positions.forEach((position) => {
      slots.push(...position.timeSlots);
    });
    return slots;
  }

  public set participations(participations: ParticipationModel[]) {
    const tss = new Map<number, TimeSlotModel>();
    for (const position of this.positions) {
      for (const ts of position.timeSlots) {
        if (ts.id !== null) {
          tss.set(ts.id, ts);
        }
      }
    }

    for (const p of participations) {
      if (p.timeSlotId) {
        if (tss.has(p.timeSlotId)) {
          tss.get(p.timeSlotId)!.participations.push(p);
        }
      }
    }
  }

  public get participations(): ParticipationModel[] {
    const participations: ParticipationModel[] = [];
    for (const position of this.positions) {
      for (const ts of position.timeSlots) {
        for (const v of ts.participations) {
          participations.push(v);
        }
      }
    }
    return participations;
  }

  public getTags(): TagModel[] {
    const tags: TagModel[] = [];
    this.positions.forEach((position) => {
      position.tags.forEach((tag) => {
        if (!tags.map((t) => Number(t.id)).includes(Number(tag.id))) {
          tags.push(tag);
        }
      });
    });

    tags.sort((a, b) => {
      if (a.category && b.category) {
        if (a.category.order < b.category.order) { return -1; }
        if (a.category.order > b.category.order) { return 1; }
      }
      return 0;
    });

    return tags;
  }

  public get filteredPositions() {
    const positions: PositionModel[] = [];
    for (const position of this.positions) {
      if (this.filteringMissions.length > 0) {
        if (this.filteringMissions.indexOf(position) === -1) { continue; }
      }
      if (this.filteringTags.length > 0) {
        if (!this.filteringTags.every((filter) => position.listOfTagsId.some((item) => filter.tags.map((t: TagModel) => Number(t.id)).includes(item)))) {
          continue;
        }
      }
      positions.push(position);
    }
    return positions;
  }

  public getFilteredPositionWithSlotInDateRange(
    startDate: DateTime,
    endDate: DateTime,
    withPrivate: boolean,
    withEmptyPosition: boolean = false,
  ): PositionModel[] {
    const positions: PositionModel[] = [];
    for (const position of this.filteredPositions) {
      if (position.didContainSlotInDateRange(startDate, endDate, withPrivate) || withEmptyPosition) {
        positions.push(position);
      }
    }
    return positions;
  }

  public getAllSlotIdForDayIndexWithoutParticipations(day: EventDayModel, withPrivate: boolean): number[] {
    const slotsIds: number[] = [];
    this.positions.forEach((position) => {
      slotsIds.push(...position.slotsInRange(day.startsAt, day.endsAt, withPrivate).map((ts) => Number(ts.id)));
    });
    return slotsIds;
  }



  public setParticipationsToSlots(slots: TimeSlotModel[], parts: ParticipationModel[]): TimeSlotModel[] {
    slots.forEach((slot) => {
      slot.participations = slot.getParticipations(parts);
    });
    return slots;
  }

  public getSlotForRange(
    rangeStart: DateTime,
    rangeEnd: DateTime,
    posIndex: number | null,
    withPrivate: boolean,
  ): TimeSlotModel[] {

    const slots: TimeSlotModel[] = [];
    let _positions = [];

    if (posIndex === null) {
      _positions = this.getFilteredPositionWithSlotInDateRange(rangeStart, rangeEnd, withPrivate);
    } else {
      _positions = this.positions;
    }

    for (const position of _positions) {
      if (posIndex === null || position.id === posIndex) {
        const slts = position.slotsInRange(rangeStart, rangeEnd, withPrivate);

        slts.forEach((slot) => {
          slot.position = position;
          if (this.filteringRewardCategories.length > 0) {
            if (this.filteringRewardCategories.map((c) => c.id).includes(slot.rewardCategory)) {
              slots.push(slot);
            }
          } else {
            slots.push(slot);
          }
        });
      }
    }

    return slots;
  }

  public getSlotForDay(day: EventDayModel, withPrivate: boolean = false): TimeSlotModel[] {
    return this.getSlotForRange(day.startsAt, day.endsAt, null, withPrivate);
  }

  public getSlotsInfoForDay(day: EventDayModel, withPrivate: boolean = false) {
    return `[${this.getSlotForDay(day, withPrivate).length}]`;
  }

  public checkIfAtLeast1HoursIsAvailable(
    day: EventDayModel,
    filteredPositions: PositionModel[] | null = null,
    withPrivate: boolean): boolean {

    let result = false;
    for (const position of this.positions) {

      if (filteredPositions) {
        if (filteredPositions.indexOf(position) === -1) { continue; }
      }

      const slts = position.slotsInRange(day.startsAt, day.endsAt, withPrivate);
      const minimalDuration = 60;
      let freeSpace = false;
      let startingPoint = day.startsAt.toMillis();

      slts.forEach((slot) => {
        // Check si entre le dernier point et le debut du prochain slot il y'a la durée requise
        freeSpace = freeSpace || Math.floor((slot.startTime.toMillis() - startingPoint) / 60000) >= minimalDuration;
        startingPoint = slot.endTime.toMillis();
      });

      // Check le dernier slot -> fin du jour
      // Dans le cas de 0 slot => debut -> fin du jour 
      freeSpace = freeSpace || Math.floor((day.endsAt.toMillis() - startingPoint) / 60000) >= minimalDuration;
      return freeSpace;
    }

    return result;
  }

  public getDayIndexForStringDate(stringDate: string): number {
    const test = DateTime.fromISO(stringDate, { zone: this.timeZone }).startOf('day').toJSDate().getTime();
    return this.days.findIndex((d) => {
      return d.date.getTime() === test;
    });
  }

  public getDayForStringDate(stringDate: string): EventDayModel | undefined {
    return this.days.find((d) => {
      return d.startsAtYYYYMMDD() === stringDate;
    });
  }


  public slotWithId(slotId: number): TimeSlotModel | null {
    for (const position of this.positions) {
      const slot = position.slotWithId(slotId);
      if (slot !== null) {
        return slot;
      }
    }
    return null;
  }

  public getSlotPosition(slotId: number): PositionModel {
    let result = new PositionModel();
    for (const position of this.positions) {
      for (const ts of position.timeSlots) {
        if (ts.id === slotId) {
          result = position;
        }
      }
    }
    return result;
  }

  public getPositionForId(positionId: number): PositionModel | null {
    for (const position of this.positions) {
      if (position.id === positionId) {
        return position;
      }
    }
    return null;
  }

  public isMaxZoom(): boolean {
    return this.zoomLevel === this.zoom.length - 1;
  }
  public isMinZoom(): boolean {
    return this.zoomLevel === 0;
  }
  public get schedulerStepWidth(): number {
    return this.size[this.zoomLevel];
  }
  public get schedulerStepSize(): number {
    return this.zoom[this.zoomLevel];
  }
  private get orderedFields(): CustomFieldModel[] {
    if (this.configuration.customFields) {
      return this.configuration.customFields.slice().sort();
    }
    return [];
  }

  public updateTimeZone(newTimeZone: string) {
    this.timeZone = newTimeZone;

    this.days.forEach((day) => {
      const startST = day.startsAt.toISO({ includeOffset: false });
      const endST = day.endsAt.toISO({ includeOffset: false });
      if (startST && endST) {
        day.startsAt = DateTime.fromISO(startST, { zone: newTimeZone });
        day.endsAt = DateTime.fromISO(endST, { zone: newTimeZone });
      }
    });

    this.slots.forEach((slot) => {
      const startST = slot.startTime.toISO({ includeOffset: false });
      const endST = slot.endTime.toISO({ includeOffset: false });
      if (startST && endST) {
        slot.startTime = DateTime.fromISO(startST, { zone: newTimeZone });
        slot.endTime = DateTime.fromISO(endST, { zone: newTimeZone });
      }
    });

    this.deepUpdateNeeded = true;
  }


  // Filtering
  public get numberOfFilters(): number {
    return this.filteringMissions.length +
      this.filteringRewardCategories.length +
      this.filteringTags.map((item) => item.tags).flat().length;
  }
  /*
  category: TagModel
  tags: TagModel[] inside the category
  */
  public filteringTags: any[] = [];
  public filteringMissions: PositionModel[] = [];
  public filteringRewardCategories: RewardCategoryModel[] = [];


}


